import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import {
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { authInterceptorProviders } from './@core/auth/interceptors/auth.interceptor';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { AccountService } from './@core/account/data-access/account.service';
import { NgxPermissionsModule } from 'ngx-permissions';
import { CommonModule } from '@angular/common';


export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideHttpClient(withInterceptorsFromDi()),
    authInterceptorProviders,
    provideAnimationsAsync(),
    {
      provide: APP_INITIALIZER,
      useFactory: (accountService: AccountService) => () => Promise.resolve(),
      multi: true,
      deps: [AccountService],
    },
    importProvidersFrom(NgxPermissionsModule.forRoot()),
  ],
};  
