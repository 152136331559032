import { Routes } from '@angular/router';
import { LoginComponent } from './@core/auth/features/login/login.component';
import { MapService } from './@core/map/data-access/services/map.service';
import { AuthGuard } from './@core/auth/guards/auth.guard';
import { SidebarService } from './@core/map/ui/sidebar/data-access/sidebar.service';
import { HeaderService } from './@core/map/ui/sidebar/features/header/data-access/header.service';
import { AdminGuard } from './@core/account/guards/role.guard';
import { LayersService } from './@core/map/features/layers-page/data-access/services/layers.service';

export const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'map',
    providers: [MapService, SidebarService, HeaderService, LayersService],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./@core/map/routing/map.routes').then((m) => m.routes),
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./@admin/routing/admin.routes').then((m) => m.routes),
    canActivate: [AdminGuard],
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'map',
  },
];
