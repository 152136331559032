<section class="min-h-screen flex flex-col items-center justify-center bg-gradient p-6">
  <header class="mb-8">
  </header>
  <main class="w-full max-w-md bg-white p-8 rounded-lg shadow-lg">
    <form
      [formGroup]="loginForm"
      (ngSubmit)="onSubmit()"
      class="space-y-6"
    >
      <h1 class="text-3xl font-bold text-gray-900 text-center">Login</h1>
      <div>
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>Email</mat-label>
          <input
            formControlName="email"
            matInput
            placeholder="example@example.com.au"
            class=""
          />
          <mat-error *ngIf="loginForm.get('email')?.errors"> </mat-error>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>Password</mat-label>
          <input 
            matInput 
            formControlName="password" 
            type="password" 
            class=""
          />
          <mat-error *ngIf="loginForm.get('password')?.errors"> </mat-error>
        </mat-form-field>
      </div>

      <div class="flex items-center justify-between">
        <button
          type="submit"
          [disabled]="!loginForm.valid"
          class="w-full flex justify-center px-4 py-2 bg-blue-600 text-white font-bold disabled:bg-slate-400 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring focus:ring-blue-300"
        >
          Login
        </button>
      </div>
    </form>
  </main>
  <footer class="mt-8 text-center">
    <a class="text-blue-500 hover:underline" routerLink="/forgot-password">Forgot Password?</a>
    <span class="block text-gray-500 mt-2">{{ version }}</span>
  </footer>
</section>
