import { Component, Inject, ViewContainerRef, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { MapComponent } from './@core/map/map/map.component';
import { NgxPermissionsService } from 'ngx-permissions';
import { AccountService } from './@core/account/data-access/account.service';
import { ComponentFactory } from './shared/services/ComponentFactory/component-factory.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, MapComponent],
  providers: [NgxPermissionsService],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent {
  title = 'risk-info-portal.web-app';

    private viewContainerRef:ViewContainerRef = inject(ViewContainerRef);
    private componentFactory:ComponentFactory = inject(ComponentFactory);


  constructor()
  {
    this.componentFactory.setTarget(this.viewContainerRef);

  }


}
